.nav-link:hover {
  cursor: pointer;
  color: var(--color-primary);
  scale: 1.1;
  transition: all 0.2s;
  font-weight: 500;
}

.nav-link:active {
  color: pink;
}
