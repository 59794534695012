.button a {
  font-size: 14px;
  color: var(--color-light);
  border-radius: var(--border-radius-primary);
  padding: 12px 30px;
  letter-spacing: 0.5px;
  transition: all 0.3s;
}

.primary-btn {
  margin-top: 20px;
  font-weight: bold;
}
.primary-btn a {
  background-color: var(--color-primary);
  margin-top: 20px;
}

.primary-btn a:hover {
  background-color: var(--color-light);
  color: var(--color-primary);
}

.secondary-btn a {
  border: 1px solid var(--color-primary);
}

.secondary-btn a:hover {
  border: 1px solid var(--color-light);
  color: var(--color-primary);
}
