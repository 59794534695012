.gaming-library-card {
  flex: 1 1 100%;
}

.gaming-library-card ul {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-primary);
  padding: calc(var(--padding-primary) / 2) 0;
}

.gaming-library-card h4 {
  font-size: 1.5rem;
}
