.most-popular-item {
  flex: 1 25%;
}

.card-wrapper {
  background-color: var(--color-dark);
  border-radius: calc(var(--border-radius-primary));
  padding: calc(var(--padding-primary) / 2);
  margin: calc(var(--margin-primary) / 5);
}

.card-wrapper:hover {
  cursor: pointer;
  scale: 1.1;
  transition: all 0.3s;
}
.most-popular-item-image {
  width: 100%;
  border-radius: calc(var(--border-radius-primary));
  margin-bottom: calc(var(--margin-primary) / 2);
}

.most-popular-item-content {
  display: flex;
  justify-content: space-between;
}

.most-popular-item-title {
  font-size: 14px;
  line-height: 1.5rem;
}
