.hero-main {
  background: url(../../assets/images/banner-bg.jpg) no-repeat top right/cover;
  margin: 25px;
  background-color: var(--color-dark);
  color: var(--color-light);
  border-radius: var(--border-radius-primary);
  padding: var(--padding-primary);
  height: 400px;
  

}

.hero-text {
  max-width: 500px;

}

.hero-title {
  font-size: 60px;
  text-transform: uppercase;
  margin-bottom: calc var(--margin-primary) / 2;
  font-weight: var(--font-weight-bold);
}

.hero-title em {
  font-style: normal;
  color: var(--color-primary);
}

.hero-subtitle {
  font-size: 20px;
  color: white;
  font-weight: var(--font-weight-normal);
  margin-bottom: calc var(--margin-primary) / 2;
}
