footer p {
  text-align: center;
  padding: calc(var(--padding-primary) / 2);
  color: var(--color-light);
  font-weight: 300;
}

footer p a {
  color: var(--color-light);
  transition: all 0.3s;
  font-weight: bold;
}

footer p a:hover {
  color: var(--color-primary);
}
